<template>
  <div>
    <nav class="navbar navbar-light bg-light" style="display: none">
      <div class="container-fluid">
        <a class="navbar-brand">Navbar</a>
        <form class="d-flex" style="column-gap: 1rem">
          <!-- <input
            class="form-control me-2"
            type="search"
            placeholder="Search"
            aria-label="Search"
          />
          <button class="btn btn-outline-success" type="submit">Search</button> -->
          <label>Select Langauge</label>
          <select
            @change="selectLanguage($event)"
            v-model="lang"
            class="form-select"
            aria-label="Default select example"
          >
            <option value="en">English</option>
            <option value="ar">Arabic</option>
            <option value="gr">German</option>
          </select>
        </form>
      </div>
    </nav>

    <div class="main">
      <!-- Sing in  Form -->
      <section class="sign-in">
        <div class="container" style="min-height: 44rem">
          <div v-if="loading">
            <div class="d-flex justify-content-center">
              <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          </div>
          <br>
          <div class="ticker-wrap" v-if="this.ticker.message">
            <marquee onmouseover="this.stop();" onmouseout="this.start();" v-html="ticker.message" scrollamount="3"></marquee>
          </div>
          <br>
          <div class="signin-content">
            <div class="signin-image" style="margin: auto auto">
              <figure>
                <img
                  :src="`${publicPath}/assets/img/logo.png`"
                  :alt="$t(`sign up image`)"
                />
              </figure>
              <a class="signup-image-link">Medical Health Covers On-line</a>
            </div>
            <div class="signin-form">
              <h2 class="text-center form-title set-width">
                {{ $t(`Login`) }}
              </h2>
              <form
                class="register-form"
                id="login-form"
                @submit.prevent="login()"
              >
                <div class="form-group">
                  <div class="">
                    <i class="fa fa-address-book-o icon-styling"></i>
                    <input
                      style="width: 88%; padding-left: 29px"
                      v-model="userData.emailOrUsername"
                      name="uname"
                      type="text"
                      :placeholder="$t(`User Name`)"
                      class="form-control input-height"
                      required
                    />
                  </div>
                </div>

                <div class="form-group">
                  <div class="d-flex align-items-center">
                    <i class="fa fa-lock icon-styling"></i>
                    <input
                      style="width: 88%; padding-left: 29px"
                      v-model="userData.password"
                      name="pwd"
                      :type="!showPasswordCheck ? 'password' : 'text'"
                      :placeholder="$t(`Password`)"
                      class="form-control input-height w-88"
                      required
                    />

                    <i
                      class="fa-solid fa-eye ms-2 me-1"
                      style="cursor: pointer"
                      @click.prevent="showPassword()"
                    ></i
                    ><label
                      for=""
                      class="hide-label"
                      @click.prevent="showPassword()"
                      style="font-weight: 550; cursor: pointer"
                      >Show</label
                    >
                  </div>
                </div>
                <div class="form-check form-switch mb-3 ms-1">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="flexSwitchCheckDefault"
                    @click="isRemebered()"
                  />
                  <label class="form-check-label" for="flexSwitchCheckDefault"
                    >Remember me</label
                  >
                </div>
                <div class="form-group form-button mb-3">
                  <button
                    type="submit"
                    class="btn btn-round btn-primary login-button-styling"
                    name="signin"
                    id="signin"
                  >
                    {{ $t(`Login`) }}
                  </button>
                </div>
                <div class="text-center set-width">
                  <label for="">Don't have an account yet?</label>
                </div>
                <!-- <div class="dropdown mb-3">
                  <a
                    style="
                      text-decoration: underline;
                      color: #0d6efd !important;
                    "
                    role="button"
                    id="dropdownMenuLink"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Create an Account<i class="fa-solid fa-angle-down ms-1"></i>
                  </a>

                  <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                    <li>
                      <router-link to="/customer/signup" class="dropdown-item"
                        >Medical SPA Signup</router-link
                      >
                    </li>
                    <li>
                      <router-link to="/provider/signup" class="dropdown-item"
                        >Nurse Practitioner Signup</router-link
                      >
                    </li>
                  </ul>
                </div> -->
                <!-- Default dropend button -->
                <div class="set-width">
                  <div class="btn-group mt-2 mb-3" style="width: 100%">
                    <button
                      type="button"
                      class="btn btn-secondary dropdown-toggle signup-button-styling"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Signup
                    </button>
                    <ul class="dropdown-menu">
                      <li>
                        <router-link to="/customer/signup" class="dropdown-item"
                          >Medical SPA Signup</router-link
                        >
                      </li>
                      <li>
                        <router-link to="/provider/signup" class="dropdown-item"
                          >Nurse Practitioner Signup</router-link
                        >
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  class="d-flex align-items-center justify-content-between set-width"
                >
                  <a
                    style="text-decoration: underline; color: #636363"
                    href="#"
                    class="style-on-hover"
                  >
                    Forget Username?
                  </a>
                  <router-link
                    style="text-decoration: underline; color: #636363"
                    to="/forgot/password"
                    class="style-on-hover"
                  >
                    Forget Password?
                  </router-link>
                </div>
              </form>
            </div>
          </div>
          <div>
            <div class="set-footer-links-styling">
              <a
                class="btn me-3 set-color-style"
                target="_blank"
                href="https://app.healthcoversonline.com/patient/home"
                >Patient Check-In</a
              ><a
                class="btn me-3 set-color-style"
                target="_blank"
                href="https://healthcoversonline.com/"
                >About Us</a
              ><a
                class="btn me-3 set-color-style"
                target="_blank"
                href="https://healthcoversonline.com/wp-content/themes/healthcoversdi336/pdf/privacy-policy.pdf"
                >Privacy Policy</a
              ><a
                class="btn me-3 set-color-style"
                target="_blank"
                href="https://healthcoversonline.com/wp-content/themes/healthcoversdi336/pdf/terms-of-use.pdf"
                >Terms of Use</a
              ><a
                class="btn me-3 set-color-style"
                target="_blank"
                href="https://healthcoversonline.com/good-faith-evaluation-service-contact-us"
                >Contact Us</a
              >
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import cookie from "../helper/cookieHelpers";
export default {
  data() {
    const lang = localStorage.getItem("lang") || "en" || "gr" || "ar";
    return {
      publicPath: window.location.protocol,
      lang: lang,
      userData: {
        emailOrUsername: null,
        password: null,
      },
      loading: false,
      showPasswordCheck: false,
      setRememberMeValue: "false",
      invoiceId: null,
      ticker: {
        message: "",
      },
    };
  },

  methods: {
    ...mapActions(["userLogin"]),
    ...mapActions(["fetchCustomer"]),
    ...mapActions(["fetchProviderById"]),
    ...mapActions(["fetchDirectorById"]),
    // ...mapActions(["fetchCustomerMedicalDirector"]),
    ...mapActions(["fetchTicker"]),

    async fetch() {
      var data = await this.fetchTicker();
      if (data?.status == 200) {
        this.ticker.message = data?.payload[0].message.replace(/^<p>(.*)<\/p>$/i, '$1');
      }
    },
    isRemebered() {
      if (this.setRememberMeValue == "true") {
        this.setRememberMeValue = "false";
        localStorage.setItem("rememberMe", "false");
      } else if (this.setRememberMeValue == "false") {
        this.setRememberMeValue = "true";
        localStorage.setItem("rememberMe", "true");
      }
      //this.setRememberMeValue == 'true' ? localStorage.setItem("rememberMe", "true") :
    },

    async login() {
      cookie.removeCookie("providerContractToken");
      cookie.removeCookie("customerContractToken");

      if (!this.userData.emailOrUsername || !this.userData.password) {
        swal({
          title: "Credentials Error",
          text: "Please enter credentials",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        });
        return;
      }

      this.loading = true;
      var data = await this.userLogin(this.userData);

      if (data?.status == 200) {
        this.invoiceId = parseInt(localStorage.getItem("invoiceId"));
        if (data.payload?.data.type.toLowerCase() == "customer") {
          var fetchCustomerById = await this.fetchCustomer(
            data?.payload?.data?.id
          );
          if (fetchCustomerById?.status == 200) {
            var name = fetchCustomerById?.payload.companyName;
            this.setUserNameInLocalStorage(name);

            if (!this.invoiceId) {
              this.$router.push("/customer/dashboard");
            } else {
              this.$router.push("/invoice?id=" + this.invoiceId);
            }
          }
          this.loading = false;
        } else if (data.payload?.data.type.toLowerCase() == "admin") {
          if (!this.invoiceId || this.invoiceId == undefined) {
            this.$router.push("/admin/dashboard");
          } else {
            this.$router.push("/invoice?id=" + this.invoiceId);
          }
          this.loading = false;
        } else if (data.payload?.data.type.toLowerCase() == "provider") {
          var fetchProvider = await this.fetchProviderById(
            data?.payload?.data?.id
          );
          if (fetchProvider?.status == 200) {
            var name =
              fetchProvider?.payload.firstname +
              " " +
              fetchProvider?.payload.lastname;
            this.setUserNameInLocalStorage(name);
            this.$router.push("/provider/dashboard");
          }
          this.loading = false;
        } else if (
          data.payload?.data.type.toLowerCase() == "medical director" ||
          data.payload?.data.type.toLowerCase() == "clinical director"
        ) {
          var fetchDirectorById = await this.fetchDirectorById(
            data?.payload?.data?.id
          );
          if (fetchDirectorById?.status == 200) {
            var name =
              fetchDirectorById?.payload.firstname +
              " " +
              fetchDirectorById?.payload.lastname;
            this.setUserNameInLocalStorage(name);
            this.$router.push("/gfe/list");
          }
          this.loading = false;
        } else if (
          data.payload?.data.type.toLowerCase() == "customer medical director"
        ) {
          // var fetchCustomerMedicalDirectorById = await fetchCustomerMedicalDirector(data?.payload?.data?.id);
          // if(fetchCustomerMedicalDirectorById?.status == 200) {
          //   var name = fetchCustomerMedicalDirectorById?.payload.firstname + " " + fetchCustomerMedicalDirectorById?.payload.lastname;
          //   this.setUserNameInLocalStorage(name);
          //   this.$router.push("/gfe/list");
          // }
          this.$router.push("/customer/director");
          this.loading = false;
        } else {
          this.loading = false;

          swal({
            title: "Invalid emailOrUsername or Password",
            //text: "Username or Password is incorrect",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          });
        }
      } else if (data?.status == 400) {
        this.loading = false;

        swal({
          //title: "User not found",
          title: "Username or Password is incorrect",
          icon: "warning",
          buttons: "Okay",
          dangerMode: true,
        });
      } else if (data?.status == 4006) {
        this.loading = false;

        swal({
          //title: "User not found",
          title: "Inactive",
          text: "The account is currently inactive",
          icon: "warning",
          buttons: "Okay",
          dangerMode: true,
        });
        return;
      } else {
        this.loading = false;

        swal({
          title: "User not found",
          //title: "Username or Password is incorrect",
          icon: "warning",
          buttons: "Okay",
          dangerMode: true,
        });
      }
    },

    showPassword() {
      if (this.showPasswordCheck) {
        this.showPasswordCheck = false;
      } else if (!this.showPasswordCheck) {
        this.showPasswordCheck = true;
      }
    },

    setUserNameInLocalStorage(name) {
      localStorage.setItem("username", name);
    },

    selectLanguage(event) {
      localStorage.setItem("lang", event.target.value);
      window.location.reload();
    },
  },

  created() {
    localStorage.setItem("rememberMe", "false");
  },
  mounted() {
    this.fetch();
  },
};
</script>

<style scoped>
input {
  width: 80% !important;
}

.signin-form {
  min-width: 24rem;
  /* margin-right: 50px; */
}

figure {
  margin-bottom: 30px !important;
}

.signup-image-link {
  font-size: 17px;
  color: #222;
  display: block;
  text-align: center;
}

.icon-styling {
  position: absolute;
  top: 15px;
  left: 10px;
  font-size: 16px;
}

.login-button-styling {
  width: 80%;
  font-size: 17px !important;
  border-radius: 10px;
}

input,
label,
a,
i {
  color: rgb(65, 84, 147) !important;
}

.style-on-hover:hover {
  color: #0d6efd !important;
}

.set-color-style {
  color: #51b7bc !important;
}

.form-check-input {
  width: 30px !important;
  height: 17px !important;
}

.container {
  min-height: 47rem !important;
}

.signup-button-styling {
  background-color: transparent;
  color: #6da0d6;
  border: 1px solid;
  /* width: 132px; */
  height: 51px;
  font-size: 18px;
  padding: 0 43px;
}

.dropdown-toggle::after {
  display: none !important;
}

.signin-content {
  padding-bottom: 67px !important;
}

/* .dropdown-menu {
  left: -10rem !important;
  
} */

.signin-form {
  overflow: unset !important;
}

.set-width {
  width: 80% !important;
}

.set-footer-links-styling {
  display: flex;
  column-gap: 20px;
  justify-content: center;
}

.container {
  max-width: calc(95% - 30px);
}

.main {
  height: 100vh !important;
}

.set-footer-links-styling {
  padding-bottom: 15px;
}

.signin-image {
  /* margin-left: 110px !important;
  margin-right: 50px !important; */
  margin: auto 50px auto 107px !important;
}

@media (max-height: 875px) and (max-width: 768px) {
  .main {
    height: 120vh !important;
  }
}

@media (max-height: 700px) {
  .main {
    height: 122vh !important;
  }
}

@media (max-height: 700px) {
  .main {
    height: 130vh !important;
  }
}

@media (max-width: 768px) {
  .hide-label {
    display: none !important;
  }

  figure {
    margin-bottom: 0px !important;
  }

  input {
    width: 100% !important;
  }

  img {
    height: 125px !important;
  }

  .set-width {
    width: 100% !important;
  }

  .set-footer-links-styling {
    flex-wrap: wrap;
  }

  .set-color-style {
    width: 100%;
  }

  .signin-image {
    margin-bottom: 0px !important;
    margin: auto 50px 0px 60px !important;
  }

  input {
    width: 100% !important;
  }

  .fa-solid {
    position: absolute !important;
    right: 6px !important;
  }

  .set-color-style {
    margin-bottom: 8px;
  }
}

@media (max-width: 1200px) {
  .set-footer-links-styling {
    padding-bottom: 15px !important;
  }
}

@media (max-width: 650px) {
  .set-footer-links-styling {
    column-gap: 0px !important;
  }
}

@media (max-width: 460px) {
  .container {
    width: calc(95% - 30px);
    max-width: 100%;
  }
}

@media (max-width: 450px) and (max-height: 670px) {
  .main {
    height: 145vh !important;
  }
}
.ticker-wrap {
  width: 102.6%;
  right: 10.5px;
  padding-top: 3px;
  background-color: #0095a4;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
}

.ticker-wrap marquee {
  color: #fff;
  font-size: 16px;
}

@media (max-width: 768px) {
  .ticker-wrap marquee {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .ticker-wrap marquee {
    font-size: 12px;
  }
}

@media only screen and (min-device-width: 414px) and (max-device-width: 896px) and (-webkit-min-device-pixel-ratio: 3), (min-device-pixel-ratio: 3) {
  .ticker-wrap marquee {
    font-size: 14px;
  }
}

</style>
