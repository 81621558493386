import url from "../constants/urls";
import apiHelper from "../helper/apiHelper";

async function fetchProviderDashboardData() {
  return await apiHelper.makeApiCall(
    url.API_URL + url.PROVIDER_DASHBOARD,
    "GET",
    null
  );
}

async function providerPresencePing() {
  return await apiHelper.makeApiCall(
    url.API_URL + url.PROVIDER_PRESENCE_PING,
    "POST",
    null
  );
}

async function getCurrentOnlineProvidersCount() {
  return await apiHelper.makeApiCall(
    url.API_URL + url.GET_ONLINE_PROVIDERS_COUNT,
    "GET",
    null
  );
}

async function getProviderLocations(id) {
  return await apiHelper.makeApiCall(
      url.API_URL + url.GET_PROVIDER_LOCATION.replace(':id', id),
      "GET",
      null
  );
}

async function createProviderLocation(data) {
  return await apiHelper.makeApiCall(
      url.API_URL + url.CREATE_PROVIDER_LOCATION,
      "POST",
      data
  );
}

export default {
  fetchProviderDashboardData,
  providerPresencePing,
  getCurrentOnlineProvidersCount,
  getProviderLocations,
  createProviderLocation
};
