import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./i18n";
import VueTelInput from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";
import CKEditor from "@ckeditor/ckeditor5-vue";
import Select2 from "vue3-select2-component";
import * as Sentry from "@sentry/vue";

Sentry.init({
    App,
    dsn: "https://edc2ed7b4ab9a50875cb2d9acff1a505@o4507373454098432.ingest.us.sentry.io/4507379325534208",
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [/^https:\/\/app.healthcoversonline.com/,/^https:\/\/app-dev.healthcoversonline.com/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
//const lang = localStorage.getItem("lang") || "en";

createApp(App)
  .use(i18n)
  .use(store)
  .use(router)
  .use(VueTelInput)
  // .use(VeeValidate)
  .use(CKEditor)
  .component("Select2", Select2)
  .mount("#app");
