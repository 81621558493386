export default {
  "Sign up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign up"])},
  "Company Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Name"])},
  "Contact Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Name"])},
  "Contact Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Number"])},
  "Company Address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Address"])},
  "Contact Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Email"])},
  "DBA Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DBA Name"])},
  "Business Website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business Website"])},
  "Do you have multiple locations?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you have multiple locations?"])},
  "True": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["True"])},
  "False": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["False"])},
  "Dropdown Button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dropdown Button"])},
  "California": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["California"])},
  "Billing Contact Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billing Contact Name"])},
  "Billing Phone Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billing Phone Number"])},
  "Medical Group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medical Group"])},
  "Medical Directors Info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medical Directors Info"])},
  "First Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name"])},
  "Last Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name"])},
  "Phone Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone Number"])},
  "Must be Director's Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Must be Director's Email"])},
  "License Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["License Type"])},
  "Medical License Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medical License Number"])},
  "Register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])},
  "sign up image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sign up image"])},
  "Login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
  "User Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Name"])},
  "Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "Customer Sign up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Sign up"])},
  "Provider Sign up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provider Sign up"])},
  "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["required"])},
  "optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["optional"])}
}