import url from "../constants/urls";
import apiHelper from "../helper/apiHelper";

async function login({ emailOrUsername, password }) {
  const userData = { emailOrUsername, password };

  return await apiHelper.makeApiCall(
    url.API_URL + url.USER_LOGIN,
    "POST",
    userData
  );
}

async function providerSignup({
  firstname,
  lastname,
  phone,
  address,
  email,
  licenseInfo,
}) {
  const providerSignupData = {
    firstname,
    lastname,
    phone,
    address,
    email,
    licenseInfo,
  };

  return await apiHelper.makeApiCall(
    url.API_URL + url.PROVIDER_SIGNUP,
    "POST",
    providerSignupData
  );
}

async function customerSignup(
  customerData = {
    companyName,
    companyPhone,
    contactName,
    companyAddress,
    companyEmail,
    dbaName,
    businessWebsite,
    multipleLocations,
    stateOperateIn: [],
    billingContactName,
    billingContactNumber,
    medicalGroup: {
      firstname,
      lastname,
      phone,
      email,
      licenseType,
    },
  }
) {
  return await apiHelper.makeApiCall(
    url.API_URL + url.CUSTOMER_SIGNUP,
    "POST",
    customerData
  );
}

async function fetchCustomers() {
  return await apiHelper.makeApiCall(
    url.API_URL + url.FETCH_CUSTOMERS,
    "GET",
    null
  );
}

async function fetchMedicalDirectorCustomers() {
  return await apiHelper.makeApiCall(
    url.API_URL + url.FETCH_MEDICAL_DIRECTOR_CUSTOMERS,
    "GET",
    null
  );
}

async function fetchProviders() {
  return await apiHelper.makeApiCall(
    url.API_URL + url.FETCH_PROVIDERS,
    "GET",
    null
  );
}

async function updateCustomer({ id, status }) {
  return await apiHelper.makeApiCall(
    url.API_URL + url.UPDATE_CUSTOMER,
    "PATCH",
    { id, status }
  );
}

async function updateProvider(data) {
  return await apiHelper.makeApiCall(
    url.API_URL + url.UPDATE_PROVIDER,
    "PATCH",
    data
  );
}

async function filterCustomers(status) {
  return await apiHelper.makeApiCall(
    url.API_URL + url.FILTER_CUSTOMER + status,
    "GET",
    null
  );
}

async function filterProviders(status) {
  return await apiHelper.makeApiCall(
    url.API_URL + url.FILTER_PROVIDER + status,
    "GET",
    null
  );
}

async function CreateDirector({ firstname, lastname, email, accountType }) {
  return await apiHelper.makeApiCall(
    url.API_URL + url.CREATE_DIRECTOR,
    "POST",
    {
      firstname,
      lastname,
      email,
      accountType,
    }
  );
}

async function createSignature(file) {
  return await apiHelper.makeFileApiCall(
    url.API_URL + url.SIGNATURE_FILE,
    "POST",
    file
  );
}

async function fetchCustomerById(id) {
  return await apiHelper.makeApiCall(
    url.API_URL + url.FETCH_CUSTOMER_ID + id,
    "GET",
    null
  );
}

async function uploadCustomerContract({
  token,
  signatureUrl,
  customerSignatureUrl,
  effectiveDate,
  companyName,
  companyLocation,
  subscriptionFee,
  customerMedicalDirector,
}) {
  return await apiHelper.makeApiCall(
    url.API_URL + url.UPLOAD_CUSTOMER_CONTRACT,
    "POST",
    {
      token,
      signatureUrl,
      customerSignatureUrl,
      effectiveDate,
      companyName,
      companyLocation,
      subscriptionFee,
      customerMedicalDirector,
    }
  );
}

async function addCategory({ name, questions }) {
  return await apiHelper.makeApiCall(url.API_URL + url.ADD_CATEGORY, "POST", {
    name,
    questions,
  });
}

async function fetchCategories() {
  return await apiHelper.makeApiCall(
    url.API_URL + url.FETCH_CATEGORIES,
    "GET",
    null
  );
}

async function addTreatment({
  categoryid,
  name,
  deniedText,
  deferText,
  approvedText,
}) {
  return await apiHelper.makeApiCall(url.API_URL + url.ADD_TREATMENT, "POST", {
    categoryid,
    name,
    deniedText,
    deferText,
    approvedText,
  });
}

async function fetchTreatments() {
  return await apiHelper.makeApiCall(
    url.API_URL + url.FETCH_TREATMENTS,
    "GET",
    null
  );
}

async function addPatient(
  patientData = {
    firstname,
    lastname,
    dob,
    email,
    medications,
    allergies,
    medicalHistory: {
      pregnantOrBreastfeeding,
      historyOfTreatments,
      currentMedications,
      knownAllergies,
      surgicalHistory,
      knownMedicalConditions,
      permenantImplant,
      isSkinInfected,
      isPatientExperiencingOutbreak,
    },
  }
) {
  return await apiHelper.makeApiCall(
    url.API_URL + url.ADD_PATIENT,
    "POST",
    patientData
  );
}

async function fetchPatients() {
  return await apiHelper.makeApiCall(
    url.API_URL + url.FETCH_PATIENT,
    "GET",
    null
  );
}

async function fetchPatientById(id) {
  return await apiHelper.makeApiCall(
    url.API_URL + url.FETCH_PATIENT_ID + id,
    "GET",
    null
  );
}

async function addGfe({ patientId, treatments, customerLocationId }) {
  return await apiHelper.makeApiCall(url.API_URL + url.CREATE_GFE, "POST", {
    patientId,
    treatments,
    customerLocationId,
  });
}

async function fetchGFes(
  fromStartedAt = null,
  toStartedAt = null,
  status = null,
  fromCreatedAt = null,
  toCreatedAt = null,
  fromCompletedAt = null,
  toCompletedAt = null,
  customerId = null,
  providerId = null,
  patientId = null,
  pageNo = null,
  pageSize = null,
  searchString = null,
  customerlocationid = null,
  sorttype = null,
  orderby = null
) {
  let apiUrl = url.API_URL + url.FETCH_GFE + "?";
  // if (fromStartedAt != null && toStartedAt != null)
  //   apiUrl += `?fromStartedAt=${fromStartedAt}&toStartedAt=${toStartedAt}`;

  if (fromStartedAt != null)
    apiUrl += `fromStartedAt=${encodeURIComponent(fromStartedAt)}&`;
  if (toStartedAt != null)
    apiUrl += `toStartedAt=${encodeURIComponent(toStartedAt)}&`;
  if (status != null) apiUrl += `status=${encodeURIComponent(status)}&`;
  if (fromCreatedAt != null)
    apiUrl += `fromCreatedAt=${encodeURIComponent(fromCreatedAt)}&`;
  if (toCreatedAt != null)
    apiUrl += `toCreatedAt=${encodeURIComponent(toCreatedAt)}&`;
  if (fromCompletedAt != null)
    apiUrl += `fromCompletedAt=${encodeURIComponent(fromCompletedAt)}&`;
  if (toCompletedAt != null)
    apiUrl += `toCompletedAt=${encodeURIComponent(toCompletedAt)}&`;
  if (customerId != null)
    apiUrl += `customerid=${encodeURIComponent(customerId)}&`;
  if (providerId != null)
    apiUrl += `providerid=${encodeURIComponent(providerId)}&`;
  if (patientId != null)
    apiUrl += `patientid=${encodeURIComponent(patientId)}&`;
  if (pageNo != null) apiUrl += `pageNo=${encodeURIComponent(pageNo)}&`;
  if (pageSize != null) apiUrl += `pageSize=${encodeURIComponent(pageSize)}&`;
  if (searchString != null)
    apiUrl += `searchString=${encodeURIComponent(searchString)}&`;
  if (customerlocationid != null)
    apiUrl += `customerlocationid=${encodeURIComponent(customerlocationid)}&`;
  if (sorttype != null) apiUrl += `sorttype=${encodeURIComponent(sorttype)}&`;
  if (orderby != null) apiUrl += `orderby=${encodeURIComponent(orderby)}&`;

  return await apiHelper.makeApiCall(apiUrl, "GET", null);
}

async function fetchProviderById(id) {
  return await apiHelper.makeApiCall(
    url.API_URL + url.FETCH_PROVIDER_BY_ID + id,
    "GET",
    null
  );
}

async function uploadProviderContract({
  token,
  signatureUrl,
  effectiveDate,
  providerName,
  providerLocation,
  providerName2,
  rate,
}) {
  return await apiHelper.makeApiCall(
    url.API_URL + url.UPLOAD_PROVIDER_CONTRACT,
    "POST",
    {
      token,
      signatureUrl,
      effectiveDate,
      providerName,
      providerLocation,
      providerName2,
      rate,
    }
  );
}

async function fetchPatientWiseGfe(patientid) {
  return await apiHelper.makeApiCall(
    url.API_URL + url.FETCH_PATIENT_WISE_GFE + patientid,
    "GET",
    null
  );
}

async function fetchDirector() {
  return await apiHelper.makeApiCall(
    url.API_URL + url.FETCH_DIRECTOR,
    "GET",
    null
  );
}

async function fetchDirectorById(id) {
  return await apiHelper.makeApiCall(
    url.API_URL + url.FETCH_DIRECTOR + `/${id}`,
    "GET",
    null
  );
}

async function fetchQueuedGFE() {
  return await apiHelper.makeApiCall(
    url.API_URL + url.FETCH_QUEUED_GFES,
    "GET",
    null
  );
}

async function joinGFE(gfeid) {
  return await apiHelper.makeApiCall(
    url.API_URL + url.JOIN_GFE + "?id=" + JSON.stringify(gfeid),
    "POST",
    null
  );
}

async function endGFECall({
  id,
  comment,
  clinicalConsideration,
  treatments,
  questions,
}) {
  return await apiHelper.makeApiCall(url.API_URL + url.END_VIDEO_CALL, "POST", {
    id,
    comment,
    clinicalConsideration,
    treatments,
    questions,
  });
}

async function fetchLocation() {
  return await apiHelper.makeApiCall(
    url.API_URL + url.FETCH_LOCATIONS,
    "GET",
    null
  );
}

async function deleteLocation(id) {
  return await apiHelper.makeApiCall(
    url.API_URL + url.FETCH_LOCATIONS + "?id=" + id,
    "DELETE",
    null
  );
}

async function addLocation({ name, type }) {
  return await apiHelper.makeApiCall(url.API_URL + url.ADD_LOCATION, "POST", {
    name,
    type,
  });
}

async function updateLocation({ id, name, type }) {
  return await apiHelper.makeApiCall(url.API_URL + url.UPDATE_LOCATION, "PUT", {
    id,
    name,
    type,
  });
}

async function customerTreatment({ treatmentIds }) {
  return await apiHelper.makeApiCall(
    url.API_URL + url.UPDATE_CUSTOMER_TREATMENT,
    "POST",
    { treatmentIds }
  );
}

async function fetchCustomerTreatments() {
  return await apiHelper.makeApiCall(
    url.API_URL + url.FETCH_CUSTOMER_TREATMENT,
    "GET",
    null
  );
}

async function addManufacturer({
  treatmentId,
  websiteUrl,
  dosage,
  contraindication,
}) {
  return await apiHelper.makeApiCall(
    url.API_URL + url.ADD_MANUFACTURER,
    "POST",
    {
      treatmentId,
      websiteUrl,
      dosage,
      contraindication,
    }
  );
}

async function fetchManufacturer() {
  return await apiHelper.makeApiCall(
    url.API_URL + url.FETCH_MANUFACTURER,
    "GET",
    null
  );
}

async function updateManufacturer({
  id,
  dosage,
  websiteUrl,
  contraindication,
  treatmentId,
}) {
  return await apiHelper.makeApiCall(
    url.API_URL + url.UPDATE_MANUFACTURER,
    "PUT",
    {
      id,
      dosage,
      websiteUrl,
      contraindication,
      treatmentId,
    }
  );
}

async function deleteManufacturer(id) {
  return await apiHelper.makeApiCall(
    url.API_URL + url.DELETE_MANUFACTURER + "?id=" + id,
    "DELETE",
    null
  );
}

async function fetchCompliance() {
  return await apiHelper.makeApiCall(
    url.API_URL + url.FETCH_COMPLIANCES,
    "GET",
    null
  );
}

async function updateCompliance({ id, name, text }) {
  return await apiHelper.makeApiCall(
    url.API_URL + url.UPDATE_COMPLIANCES,
    "PUT",
    {
      id,
      name,
      text,
    }
  );
}

async function addCompliance({ name, text }) {
  return await apiHelper.makeApiCall(
    url.API_URL + url.ADD_COMPLIANCES,
    "POST",
    {
      name,
      text,
    }
  );
}

async function deleteCompliance(id) {
  return await apiHelper.makeApiCall(
    url.API_URL + url.DELETE_COMPLIANCES + "?id=" + id,
    "DELETE",
    null
  );
}

async function fetchPatientMedicalHistory({ firstname, lastname, dob }) {
  return await apiHelper.makeApiCall(
    url.API_URL +
      url.FETCH_PATIENT_MEDICAL_HISTORY +
      `?firstname=${firstname}&lastname=${lastname}&dob=${dob}`,
    "GET",
    null
  );
}

async function pingGFE({ id, type }) {
  return await apiHelper.makeApiCall(
    url.API_URL + url.PING_GFE + `?id=${id}&type=${type}`,
    "GET",
    null
  );
}

async function filterGFEs({ type, id }) {
  return await apiHelper.makeApiCall(
    url.API_URL + url.FILTER_GFE_CUSTOMER_PROVIDER + `?${type}=${id}`,
    "GET",
    null
  );
}

async function filterCustomerByLocation(stateid) {
  return await apiHelper.makeApiCall(
    url.API_URL + url.FILTER_CUSTOMER_BY_LOCATION + `?stateid=${stateid}`,
    "GET",
    null
  );
}

async function editPatient(
  patientData = {
    firstname,
    lastname,
    dob,
    email,
    medications,
    allergies,
    medicalHistory: {
      pregnantOrBreastfeeding,
      historyOfTreatments,
      currentMedications,
      knownAllergies,
      surgicalHistory,
      knownMedicalConditions,
      permenantImplant,
      isSkinInfected,
      isPatientExperiencingOutbreak,
    },
    id,
  }
) {
  return await apiHelper.makeApiCall(
    url.API_URL + url.UPDATE_PATIENT,
    "PUT",
    patientData
  );
}

async function addDirectorDetails(
  directorDetail = {
    token,
    address,
    licenseType,
    medicalLicenseNumber,
    amount,
    frequency,
    comment,
  }
) {
  return await apiHelper.makeApiCall(
    url.API_URL + url.CREATE_DIREECTOR_DETAIL,
    "POST",
    directorDetail
  );
}

async function updateDirectors(id, status) {
  return await apiHelper.makeApiCall(
    url.API_URL + url.UPDATE_DIRECTOR,
    "PATCH",
    id,
    status
  );
}

async function fetchGFE(id) {
  return await apiHelper.makeApiCall(
    url.API_URL + url.FETCH_GFE_BY_ID + `/${id}`,
    "GET",
    null
  );
}

async function fetchGFEPatient(id, dateTime) {
  return await apiHelper.makeApiCall(
    url.API_URL + url.FETCH_GFE_PATIENT + `/${id}?datetime=${dateTime}`,
    "GET",
    null
  );
}

async function customerMedDirectorComment({ id, comment }) {
  return await apiHelper.makeApiCall(
    url.API_URL + url.ADD_CUSTOMER_DIRECTOR_COMMENT,
    "POST",
    {
      id,
      comment,
    }
  );
}

async function updateCategory({ id, name }) {
  return await apiHelper.makeApiCall(url.API_URL + url.UPDATE_CATEGORY, "PUT", {
    id,
    name,
  });
}

async function updateTreatment({
  categoryid,
  id,
  name,
  deferText,
  deniedText,
  approvedText,
}) {
  return await apiHelper.makeApiCall(
    url.API_URL + url.UPDATE_TREATMENT,
    "PUT",
    { categoryid, id, name, deferText, deniedText, approvedText }
  );
}

async function editCredentials({
  currentPassword,
  newPassword,
  confirmPassword,
}) {
  return await apiHelper.makeApiCall(
    url.API_URL + url.EDIT_CREDENTIALS,
    "POST",
    {
      currentPassword,
      newPassword,
      confirmPassword,
    }
  );
}

async function fetchMedicalDirectors() {
  return await apiHelper.makeApiCall(
    url.API_URL + url.FETCH_MEDICAL_DIRECTOR,
    "GET",
    null
  );
}

async function connectGFE(id) {
  return await apiHelper.makeApiCall(
    url.API_URL + url.PATIENT_JOIN + `?id=${id}`,
    "POST",
    null
  );
}

async function fetchGfeRooms(data) {
  let _url = url.API_URL + url.FILTER_GFE_ROOMS + "?";

  if (data?.roomNo) _url += `roomNo=${data.roomNo}&`;
  if (data?.birthYear) _url += `birthYear=${data.birthYear}&`;
  if (data?.roomDate) _url += `roomDate=${data.roomDate}&`;
  if (data?.patientLoginTime) _url += `patientLoginTime=${data.patientLoginTime}`;

  return await apiHelper.makeApiCall(_url, "GET", null);
}

async function disconnectGFE(id) {
  return await apiHelper.makeApiCall(
    url.API_URL + url.PATIENT_DISCONNECT + `?id=${id}`,
    "POST",
    null
  );
}

async function resendCustomerContract(id) {
  return await apiHelper.makeApiCall(
    url.API_URL + url.RESEND_INVITATION_CUSTOMER + id,
    "POST",
    null
  );
}

async function resendProviderContract(id) {
  return await apiHelper.makeApiCall(
    url.API_URL + url.RESEND_INVITATION_PROVIDER + id,
    "POST",
    null
  );
}

async function forgotPassword(email) {
  return await apiHelper.makeApiCall(
    url.API_URL + url.FORGOT_PASSWORD + "?email=" + email,
    "POST",
    null
  );
}

async function resetPassword({ userId, otp, newPassword, confirmPassword }) {
  return await apiHelper.makeApiCall(url.API_URL + url.RESET_PASSWORD, "POST", {
    userId,
    otp,
    newPassword,
    confirmPassword,
  });
}

// async function fetchCustomerMedicalDirector(id) {
//   return await apiHelper.makeApiCall(
//     url.API_URL + url.FETCH_CUSTOMER_MEDICAL_DIRECTOR + id,
//     "GET",
//     null
//   );
// }

async function markUserActiveOrSuspend({ userId, isActive }) {
  return await apiHelper.makeApiCall(
    url.API_URL + url.SET_USER_STATUS_ACTIVE_OR_INACTIVE,
    "PATCH",
    {
      userId,
      isActive,
    }
  );
}

async function userEmail(data) {
  return await apiHelper.makeApiCall(
    url.API_URL + url.CHANGE_USER_EMAIL,
    "PATCH",
    data
  );
}

export default {
  login,
  providerSignup,
  customerSignup,
  fetchCustomers,
  fetchProviders,
  updateProvider,
  updateCustomer,
  filterProviders,
  filterCustomers,
  CreateDirector,
  createSignature,
  fetchCustomerById,
  uploadCustomerContract,
  addCategory,
  fetchCategories,
  addTreatment,
  fetchTreatments,
  addPatient,
  fetchPatients,
  fetchPatientById,
  addGfe,
  fetchGFes,
  fetchGFEPatient,
  fetchProviderById,
  uploadProviderContract,
  fetchPatientWiseGfe,
  fetchDirector,
  fetchQueuedGFE,
  joinGFE,
  endGFECall,
  addLocation,
  fetchLocation,
  deleteLocation,
  updateLocation,
  customerTreatment,
  fetchCustomerTreatments,
  addManufacturer,
  fetchManufacturer,
  updateManufacturer,
  deleteManufacturer,
  fetchCompliance,
  updateCompliance,
  addCompliance,
  deleteCompliance,
  fetchPatientMedicalHistory,
  pingGFE,
  filterGFEs,
  filterCustomerByLocation,
  editPatient,
  addDirectorDetails,
  updateDirectors,
  fetchGFE,
  customerMedDirectorComment,
  updateCategory,
  updateTreatment,
  editCredentials,
  fetchMedicalDirectors,
  connectGFE,
  disconnectGFE,
  resendCustomerContract,
  resendProviderContract,
  forgotPassword,
  resetPassword,
  fetchDirectorById,
  markUserActiveOrSuspend,
  userEmail,
  fetchGfeRooms,
  fetchMedicalDirectorCustomers,
};
