import userServices from "../Services/user.service";
import cookie from "../helper/cookieHelpers";

const state = {
  customer: [],
};

const getters = {
  user: (state) => {
    state.user;
  },

  allCustomers: (state) => {
    state.customer;
  },
};

const mutations = {
  customers: (state, customers) => {
    state.customer = customers;
  },

  // providerSignup: () => {
  // },
};

const actions = {
  async userLogin({ commit }, { emailOrUsername, password }) {
    const response = await userServices.login({ emailOrUsername, password });

    if (response.status == 200) {
      if (response.payload.token) {
        cookie.setCookie(
          "token",
          response.payload.token,
          new Date(response.payload.expiresAt).toUTCString()
        );
        const pstTime = new Date(
            new Date().toLocaleString("en-US", {
              timeZone: "America/Los_Angeles",
            })
        ).toString();
        localStorage.setItem("user", JSON.stringify(response));
        localStorage.setItem("loginAt", pstTime);
      }
    }
    //commit("userLogin", response.payload);
    return response;
    //   .catch((error) => );
  },

  async providerSignup(
    { commit },
    { firstname, lastname, phone, address, email, licenseInfo }
  ) {
    const response = await userServices.providerSignup({
      firstname,
      lastname,
      phone,
      address,
      email,
      licenseInfo,
    });

    //commit("providerSignup");

    return response;
  },

  async customerSignup(
    { commit },
    customerData = {
      companyName,
      companyPhone,
      contactName,
      companyAddress,
      companyEmail,
      dbaName,
      businessWebsite,
      multipleLocationsv,
      stateOperateIn: [],
      billingContactName,
      billingContactNumber,
      medicalGroup: {
        firstname,
        lastname,
        phone,
        email,
        licenseType,
      },
    }
  ) {
    const response = await userServices.customerSignup(customerData);

    //commit("");
    return response;
  },

  async fetchCustomers({ commit }) {
    const response = await userServices.fetchCustomers();
    commit("customers", response.payload);
    return response;
  },

  async fetchMedicalDirectorCustomers({ commit }) {
    const response = await userServices.fetchMedicalDirectorCustomers();
    commit("customers", response.payload);
    return response;
  },

  async fetchProviders({ commit }) {
    const response = await userServices.fetchProviders();
    //commit
    return response;
  },

  async updateCustomer({ commit }, id, status) {
    const response = await userServices.updateCustomer(id, status);
    //commit("")
    return response.payload;
  },

  async updateProvider({ commit }, data) {
    const response = await userServices.updateProvider(data);
    //commit("")
    return response.payload;
  },

  async filterCustomer({ commit }, status) {
    const response = await userServices.filterCustomers(status);
    return response.payload;
  },

  async filterProvider({ commit }, status) {
    const response = await userServices.filterProviders(status);
    return response.payload;
  },

  async createDirectors(
    { commit },
    directorData = { firstname, lastname, email, accountType }
  ) {
    const response = await userServices.CreateDirector(directorData);
    return response;
  },

  async createSign({ commit }, file) {
    const response = await userServices.createSignature(file);
    return response;
  },

  async fetchCustomer({ commit }, id) {
    const response = await userServices.fetchCustomerById(id);
    return response;
  },

  async customerContract(
    { commit },
    {
      token,
      signatureUrl,
      customerSignatureUrl,
      effectiveDate,
      companyName,
      companyLocation,
      subscriptionFee,
      customerMedicalDirector,
    }
  ) {
    const response = await userServices.uploadCustomerContract({
      token,
      signatureUrl,
      customerSignatureUrl,
      effectiveDate,
      companyName,
      companyLocation,
      subscriptionFee,
      customerMedicalDirector,
    });
    return response;
  },

  async addCategories({ commit }, { name, questions }) {
    const response = await userServices.addCategory({
      name,
      questions,
    });

    return response;
  },

  async fetchCategory({ commit }) {
    const response = await userServices.fetchCategories();
    return response;
  },

  async addTreatments(
    { commit },
    { categoryid, name, deniedText, deferText, approvedText }
  ) {
    const response = await userServices.addTreatment({
      categoryid,
      name,
      deniedText,
      deferText,
      approvedText,
    });

    return response;
  },

  async fetchTreatment({ commit }) {
    const response = await userServices.fetchTreatments();
    return response;
  },

  async addPatients(
    { commit },
    patientData = {
      firstname,
      lastname,
      dob,
      email,
      medications,
      allergies,
      medicalHistory: {
        pregnantOrBreastfeeding,
        historyOfTreatments,
        currentMedications,
        knownAllergies,
        surgicalHistory,
        knownMedicalConditions,
        permenantImplant,
        isSkinInfected,
        isPatientExperiencingOutbreak,
      },
    }
  ) {
    const response = await userServices.addPatient(patientData);
    return response;
  },

  async fetchPatient({ commit }) {
    const response = await userServices.fetchPatients();
    return response;
  },

  async fetchPatientById({ commit }, id) {
    const response = await userServices.fetchPatientById(id);
    return response;
  },

  async addGfes({ commit }, { patientId, treatments, customerLocationId }) {
    const response = await userServices.addGfe({
      patientId,
      treatments,
      customerLocationId,
    });
    return response;
  },

  async fetchGfe(
    { commit },
    {
      fromStartedAt = null,
      toStartedAt = null,
      status = null,
      fromCreatedAt = null,
      toCreatedAt = null,
      fromCompletedAt = null,
      toCompletedAt = null,
      customerId = null,
      providerId = null,
      patientId = null,
      pageNo = null,
      pageSize = null,
      searchString = null,
      customerlocationid = null,
      sorttype = null,
      orderby = null,
    }
  ) {
    const response = await userServices.fetchGFes(
      fromStartedAt,
      toStartedAt,
      status,
      fromCreatedAt,
      toCreatedAt,
      fromCompletedAt,
      toCompletedAt,
      customerId,
      providerId,
      patientId,
      pageNo,
      pageSize,
      searchString,
      customerlocationid,
      sorttype,
      orderby
    );
    return response;
  },

  async fetchProviderById({ commit }, id) {
    const response = await userServices.fetchProviderById(id);
    return response;
  },

  async providerContract(
    { commit },
    {
      token,
      signatureUrl,
      effectiveDate,
      providerName,
      providerLocation,
      providerName2,
      rate,
    }
  ) {
    const response = await userServices.uploadProviderContract({
      token,
      signatureUrl,
      effectiveDate,
      providerName,
      providerLocation,
      providerName2,
      rate,
    });
    return response;
  },

  async fetchPatientWiseGFEs({ commit }, patientid) {
    const response = await userServices.fetchPatientWiseGfe(patientid);
    return response;
  },

  async fetchDirectors({ commit }) {
    const response = await userServices.fetchDirector();
    return response;
  },

  async fetchQueuedGFEs({ commit }) {
    const response = await userServices.fetchQueuedGFE();
    return response;
  },

  async gfeJoin({ commit }, gfeid) {
    const response = await userServices.joinGFE(gfeid);
    return response;
  },

  async gfeEndCall(
    { commit },
    { id, comment, clinicalConsideration, treatments, questions }
  ) {
    const response = await userServices.endGFECall({
      id,
      comment,
      clinicalConsideration,
      treatments,
      questions,
    });
    return response;
  },

  async fetchLocations({ commit }) {
    const response = await userServices.fetchLocation();
    return response;
  },

  async createLocation({ commit }, { name, type }) {
    const response = await userServices.addLocation({ name, type });
    return response;
  },

  async deleteLocations({ commit }, id) {
    const response = await userServices.deleteLocation(id);
    return response;
  },

  async updLocation({ commit }, { id, name, type }) {
    const response = await userServices.updateLocation({
      id,
      name,
      type,
    });
    return response;
  },

  async updateCustomerTreatments({ commit }, { treatmentIds }) {
    const response = await userServices.customerTreatment({ treatmentIds });
    return response;
  },

  async fetchCustomerTreatment({ commit }) {
    const response = await userServices.fetchCustomerTreatments();
    return response;
  },

  async addManufacture(
    { commit },
    { treatmentId, websiteUrl, dosage, contraindication }
  ) {
    const response = await userServices.addManufacturer({
      treatmentId,
      websiteUrl,
      dosage,
      contraindication,
    });
    return response;
  },

  async fetchManufactures({ commit }) {
    const response = await userServices.fetchManufacturer();
    return response;
  },

  async updateManufacture(
    { commit },
    { id, dosage, websiteUrl, contraindication, treatmentId }
  ) {
    const response = await userServices.updateManufacturer({
      id,
      dosage,
      websiteUrl,
      contraindication,
      treatmentId,
    });
    return response;
  },

  async deleteManufacturers({ commit }, id) {
    const response = await userServices.deleteManufacturer(id);
    return response;
  },

  async fetchCompliances({ commit }) {
    const response = await userServices.fetchCompliance();
    return response;
  },

  async updateCompliances({ commit }, { id, name, text }) {
    const response = await userServices.updateCompliance({
      id,
      name,
      text,
    });
    return response;
  },

  async addCompliances({ commit }, { name, text }) {
    const response = await userServices.addCompliance({
      name,
      text,
    });
    return response;
  },

  async deleteCompliances({ commit }, id) {
    const response = await userServices.deleteCompliance(id);
    return response;
  },

  async fetchPatientForMedicalHistory(
    { commit },
    { firstname, lastname, dob }
  ) {
    const response = await userServices.fetchPatientMedicalHistory({
      firstname,
      lastname,
      dob,
    });
    return response;
  },

  async pingGfeById({ commit }, { id, type }) {
    const response = await userServices.pingGFE({ id, type });
    return response;
  },

  async filterGFEByType({ commit }, { type, id }) {
    const response = await userServices.filterGFEs({ type, id });
    return response;
  },

  async filterCustomersByLocation({ commit }, stateid) {
    const response = await userServices.filterCustomerByLocation(stateid);
    return response;
  },

  async updatePatient(
    { commit },
    patientData = {
      firstname,
      lastname,
      dob,
      email,
      medications,
      allergies,
      medicalHistory: {
        pregnantOrBreastfeeding,
        historyOfTreatments,
        currentMedications,
        knownAllergies,
        surgicalHistory,
        knownMedicalConditions,
        permenantImplant,
        isSkinInfected,
        isPatientExperiencingOutbreak,
      },
      id,
    }
  ) {
    const response = await userServices.editPatient(patientData);
    return response;
  },

  async addDirectorDetail(
    { commit },
    directorDetail = {
      token,
      address,
      licenseType,
      medicalLicenseNumber,
      amount,
      frequency,
      comment,
    }
  ) {
    const response = await userServices.addDirectorDetails(directorDetail);
    return response;
  },

  async updateDirector({ commit }, id, status) {
    const response = await userServices.updateDirectors(id, status);
    //commit("")
    return response.payload;
  },

  async fetchGFEById({ commit }, id) {
    const response = await userServices.fetchGFE(id);
    return response;
  },

  async fetchGFEPatientById({ commit }, { id, dateTime }) {
    const response = await userServices.fetchGFEPatient(id, dateTime);
    return response;
  },
  async customerMedicalDirectorComment({ commit }, { id, comment }) {
    const response = await userServices.customerMedDirectorComment({
      id,
      comment,
    });
    return response;
  },

  async updCategory({ commit }, { id, name }) {
    const response = await userServices.updateCategory({
      id,
      name,
    });
    return response;
  },

  async updTreatment(
    { commit },
    { categoryid, id, name, deferText, deniedText, approvedText }
  ) {
    const response = await userServices.updateTreatment({
      categoryid,
      id,
      name,
      deferText,
      deniedText,
      approvedText,
    });
    return response;
  },

  async editCredential(
    { commit },
    { currentPassword, newPassword, confirmPassword }
  ) {
    const response = await userServices.editCredentials({
      currentPassword,
      newPassword,
      confirmPassword,
    });
    return response;
  },

  async fetchMedicalDirector({ commit }) {
    const response = await userServices.fetchMedicalDirectors();
    return response;
  },

  async connectGfeById({ commit }, id) {
    const response = await userServices.connectGFE(id);
    return response;
  },

  async connectGfeByDOBAndId({ commit }, id) {
    const response = await userServices.connectGFE(id);
    return response;
  },

  async fetchGfeRooms({ commit }, data) {
    const response = await userServices.fetchGfeRooms(data);
    return response;
  },

  async disconnectGfeById({ commit }, id) {
    const response = await userServices.disconnectGFE(id);
    return response;
  },

  async resendInvitationContractToCustomer({ commit }, id) {
    const response = await userServices.resendCustomerContract(id);
    return response;
  },

  async resendInvitationContractToProvider({ commit }, id) {
    const response = await userServices.resendProviderContract(id);
    return response;
  },

  async forgotPassword({ commit }, email) {
    const response = await userServices.forgotPassword(email);
    return response;
  },

  async resetPassword(
    { commit },
    { userId, otp, newPassword, confirmPassword }
  ) {
    const response = await userServices.resetPassword({
      userId,
      otp,
      newPassword,
      confirmPassword,
    });
    return response;
  },

  async fetchDirectorById({ commit }, id) {
    const response = await userServices.fetchDirectorById(id);
    return response;
  },

  // async fetchCustomerMedicalDirector({ commit }, id) {
  //   const response = await userServices.fetchCustomerMedicalDirector(id);
  //   return response;
  // },

  async markUserActiveOrSuspend({ commit }, { userId, isActive }) {
    const response = await userServices.markUserActiveOrSuspend({
      userId,
      isActive,
    });
    return response;
  },

  async userEmail({ commit }, data) {
    const response = await userServices.userEmail(data);
    return response;
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
