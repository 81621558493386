import url from "../constants/urls";
import apiHelper from "../helper/apiHelper";

async function fetchOperatingHours() {
  return await apiHelper.makeApiCall(
    url.API_URL + url.FETCH_OPERATING_HOURS,
    "GET",
    null
  );
}
async function updateOperatingHours(data) {
    return await apiHelper.makeApiCall(
        url.API_URL + url.UPDATE_OPERATING_HOURS,
        "POST",
        data
    );
}
export default {
    fetchOperatingHours,
    updateOperatingHours
};
