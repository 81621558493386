import url from "../constants/urls";
import apiHelper from "../helper/apiHelper";

async function fetchInvoice() {
  return await apiHelper.makeApiCall(
    url.API_URL + url.FETCH_INVOICE,
    "GET",
    null
  );
}

async function CreateInvoice(
  invoiceSettingsData = {
    subscriptionType: "detailed",
    customerId,
    gracePeriod,
    subscriptionFee,
  }
) {
  return await apiHelper.makeApiCall(
    url.API_URL + url.CREATE_INVOICE,
    "POST",
    invoiceSettingsData
  );
}

async function fetchInvoiceById(id) {
  return await apiHelper.makeApiCall(
    url.API_URL + url.FETCH_INVOICE_BY_Id + "/" + id,
    "GET"
  );
}

async function markInvoicePayment(data) {
  return await apiHelper.makeApiCall(
    url.API_URL + url.MARK_INVOICE_PAYMENT,
    "POST",
    data
  );
}

async function makeInvoicePayment(invoiceId) {
  return await apiHelper.makeApiCall(
    url.API_URL + url.MAKE_INVOICE_PAYMENT,
    "POST",
    invoiceId
  );
}

async function filterInvoiceByStateId(stateId) {
  return await apiHelper.makeApiCall(
    url.API_URL + url.FILTER_INVOICE_BY_STATE + stateId,
    "GET"
  );
}

async function resendInvoiceToCustomer(invoiceId) {
  return await apiHelper.makeApiCall(
    url.API_URL + url.RESEND_INVOICE_BY_INVOICE_ID + invoiceId,
    "POST"
  );
}

async function generateInvoiceToCustomer(customerId) {
  return await apiHelper.makeApiCall(
    url.API_URL + url.GENERATE_INVOICE_BY_CUSTOMER_ID + customerId,
    "POST"
  );
}

async function fetchPastDueBillings(customerId) {
  let apiUrl = url.API_URL + url.GET_PAST_DUE_BILLINGS;
  if (customerId) {
    apiUrl += `?customerid=${customerId}`;
  }
  return await apiHelper.makeApiCall(apiUrl, "GET");
}

export default {
  fetchInvoice,
  CreateInvoice,
  fetchInvoiceById,
  markInvoicePayment,
  makeInvoicePayment,
  filterInvoiceByStateId,
  resendInvoiceToCustomer,
  generateInvoiceToCustomer,
  fetchPastDueBillings,
};
