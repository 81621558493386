function checkCookie(cname) {
	let cookie = getCookie(cname);
	if (cookie != "") {
		return true;
	} else {
		return false;
	}
}

function getCookie(cname) {
	let name = cname + "=";
	let decodedCookie = decodeURIComponent(document.cookie);
	let ca = decodedCookie.split(";");
	for (let i = 0; i < ca.length; i++) {
		let c = ca[i];
		while (c.charAt(0) == " ") {
			c = c.substring(1);
		}
		if (c.indexOf(name) == 0) {
			return c.substring(name.length, c.length);
		}
	}
	return "";
}

function setCookie(cname, cvalue, expiry = null) {
	document.cookie =
		cname + "=" + cvalue + (expiry ? ";expires=" + expiry : "") + ";path=/";
}

function removeCookie(cname) {
	document.cookie = cname + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/";
}

module.exports = {
	checkCookie,
	setCookie,
	getCookie,
	removeCookie,
};
