export default {
  "Sign up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmelden"])},
  "Company Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name der Firma"])},
  "Contact Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontaktname"])},
  "Contact Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt Nummer"])},
  "Company Address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firmenanschrift"])},
  "Contact Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt E-mail"])},
  "DBA Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DBA-Name"])},
  "Business Website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business-Website"])},
  "Do you have multiple locations?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haben Sie mehrere Standorte?"])},
  "True": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WAHR"])},
  "False": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FALSCH"])},
  "Dropdown Button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dropdown-Schaltfläche"])},
  "California": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalifornien"])},
  "Billing Contact Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechnungskontaktname"])},
  "Billing Phone Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechnungsnummer"])},
  "Medical Group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medizinische Gruppe"])},
  "Medical Directors Info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informationen zu Ärztlichen Direktoren"])},
  "First Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorname"])},
  "Last Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachname"])},
  "Phone Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonnummer"])},
  "Must be Director's Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muss die E-Mail-Adresse des Direktors sein"])},
  "License Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lizenz-Typ"])},
  "Medical License Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medizinische Zulassungsnummer"])},
  "Register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrieren"])},
  "sign up image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bild anmelden"])},
  "Login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmeldung"])},
  "User Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzername"])},
  "Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort"])},
  "Customer Sign up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunde anmelden"])},
  "Provider Sign up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anbieter anmelden"])},
  "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["erforderlich"])},
  "optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["optional"])}
}