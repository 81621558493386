import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/Login.vue";

//import DashboardView from "../components/Dashboard.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: {
      isAuth: false,
    },
  },
  {
    path: "/patient/list",
    name: "Patient_List",
    component: () =>
      import(
        "../components/Customer/CustomerDashboard/Patient/Patient_List.vue"
      ),
    meta: {
      isAuth: true,
      isAdmin: false,
    },
  },
  {
    path: "/provider/dashboard",
    name: "Provider_Dashboard",
    component: () => import("@/components/Provider/Provider_Dashboard.vue"),
    meta: {
      isAuth: true,
      isAdmin: false,
    },
  },
  // {
  //   path: "/director/dashboard",
  //   name: "Director",
  //   component: () => import("../views/Directors.vue"),
  //   meta: {
  //     isAuth: true,
  //     isAdmin: false,
  //   },
  // },
  {
    path: "/admin/dashboard",
    name: "AdminDashboard",
    component: () => import("@/components/Admin/AdminDashboard.vue"),
    meta: {
      isAuth: true,
      isAdmin: true,
    },
  },
  {
    path: "/director/add",
    name: "CreateDirector",
    component: () => import("../components/Admin/CreateDirector.vue"),
    meta: {
      isAuth: true,
      isAdmin: true,
    },
  },
  {
    path: "/customer/signup",
    name: "Customer_Signup",
    component: () => import("../views/Customer_Signup.vue"),
  },
  {
    path: "/provider/signup",
    name: "Provider_Signup",
    component: () => import("../views/Provider_Signup.vue"),
  },
  {
    path: "/patient/add",
    name: "Add_Patient.vue",
    component: () =>
      import(
        "../components/Customer/CustomerDashboard/Patient/Add_Patient.vue"
      ),
  },
  {
    path: "/gfe/list",
    name: "List_GFE.vue",
    component: () =>
      import("../components/Customer/CustomerDashboard/GFE/List_GFE.vue"),
    // meta: {
    //   isAuth: true,
    //   isAdmin: false,
    // },
  },
  {
    path: "/customer/profile",
    name: "Profile",
    component: () =>
      import("../components/Customer/CustomerDashboard/Profile.vue"),
  },
  {
    path: "/director/profile",
    name: "DirectorProfile",
    component: () => import("../components/DirectorProfile.vue"),
  },
  {
    path: "/customer/contracts",
    name: "CustomerContracts",
    component: () => import("../views/CustomerContracts.vue"),
  },
  {
    path: "/provider/contracts",
    name: "ProviderContracts",
    component: () => import("../views/ProviderContracts.vue"),
  },
  {
    path: "/customer/list",
    name: "CustomerList",
    component: () => import("../components/Admin/CustomerList.vue"),
    meta: {
      isAuth: true,
      isAdmin: true,
    },
  },
  {
    path: "/provider/list",
    name: "Provider",
    component: () => import("../components/Admin/ProviderList.vue"),
    meta: {
      isAuth: true,
      isAdmin: true,
    },
  },
  {
    path: "/category/add",
    name: "AddCategory",
    component: () =>
      import("../components/Admin/CategoryAndTreatments/AddCategory.vue"),
    meta: {
      isAuth: true,
      isAdmin: true,
    },
  },
  {
    path: "/treatment/add",
    name: "AddTreatment",
    component: () =>
      import("../components/Admin/CategoryAndTreatments/AddTreatment.vue"),
    meta: {
      isAuth: true,
      isAdmin: true,
    },
  },
  {
    path: "/category/treatment",
    name: "CategoryAndTreatment",
    component: () =>
      import(
        "../components/Customer/CustomerDashboard/CategoryAndTreatment.vue"
      ),
  },
  {
    path: "/gfe/add",
    name: "AddGfe",
    component: () =>
      import("../components/Customer/CustomerDashboard/GFE/AddGfe.vue"),
    meta: {
      isAuth: true,
      isAdmin: false,
    },
  },
  {
    path: "/patient/gfe",
    name: "PatientWiseGFE",
    component: () =>
      import("../components/Customer/CustomerDashboard/GFE/PatientWiseGFE.vue"),
    meta: {
      isAuth: true,
      isAdmin: false,
    },
  },
  {
    path: "/category/list",
    name: "CategoryList",
    component: () =>
      import("../components/Admin/CategoryAndTreatments/CategoryList.vue"),
    meta: {
      isAuth: true,
      isAdmin: true,
    },
  },
  {
    path: "/treatment/list",
    name: "TreatmentList",
    component: () =>
      import("../components/Admin/CategoryAndTreatments/TreatmentList.vue"),
    meta: {
      isAuth: true,
      isAdmin: true,
    },
  },
  {
    path: "/director/list",
    name: "DirectorList",
    component: () => import("../components/Admin/DirectorList.vue"),
    meta: {
      isAuth: true,
      isAdmin: true,
    },
  },
  {
    path: "/gfe/queued",
    name: "QueuedGFEs",
    component: () =>
      import("../components/Customer/CustomerDashboard/GFE/QueuedGFEs.vue"),
    meta: {
      isAuth: true,
      isAdmin: false,
    },
  },
  {
    path: "/location/add",
    name: "AddLocation",
    component: () => import("../components/Admin/AddLocation.vue"),
    meta: {
      isAuth: true,
      isAdmin: true,
    },
  },
  {
    path: "/location/list",
    name: "LocationList",
    component: () => import("../components/Admin/LocationList.vue"),
    meta: {
      isAuth: true,
      isAdmin: true,
    },
  },
  {
    path: "/customer/treatment",
    name: "CustomerTreatments",
    component: () =>
      import("../components/Customer/CustomerDashboard/CustomerTreatments.vue"),
    meta: {
      isAuth: true,
      isAdmin: false,
    },
  },
  {
    path: "/provider/category/treatment",
    name: "CategoryAndTreatments",
    component: () => import("../components/Provider/CategoryAndTreatments.vue"),
    meta: {
      isAuth: true,
      isAdmin: false,
    },
  },
  {
    path: "/patient/gfe/add",
    name: "AddPatientGFE",
    component: () =>
      import("../components/Customer/CustomerDashboard/AddPatientGFE.vue"),
    meta: {
      isAuth: true,
      isAdmin: false,
    },
  },
  {
    path: "/manufacturer/add",
    name: "AddManufacturer",
    component: () => import("../components/Admin/AddManufacturer.vue"),
    meta: {
      isAuth: true,
      isAdmin: true,
    },
  },
  {
    path: "/manufacturer/list",
    name: "ManufacturerList",
    component: () => import("../components/Admin/ManufacturerList.vue"),
    meta: {
      isAuth: true,
      isAdmin: true,
    },
  },
  {
    path: "/compliance/list",
    name: "ComplianceList",
    component: () => import("../components/Admin/ComplianceList.vue"),
    meta: {
      isAuth: true,
      isAdmin: true,
    },
  },
  {
    path: "/compliance/add",
    name: "AddCompliance",
    component: () => import("../components/Admin/AddCompliance.vue"),
    meta: {
      isAuth: true,
      isAdmin: true,
    },
  },
  {
    path: "/gfes/list",
    name: "AllGFEs",
    component: () => import("../components/Admin/AllGFEs.vue"),
    meta: {
      isAuth: true,
      isAdmin: true,
    },
  },
  {
    path: "/director/details",
    name: "DirectorSignup",
    component: () => import("../components/Admin/DirectorSignup.vue"),
  },
  {
    path: "/gfe",
    name: "ViewGfePage",
    component: () => import("../views/ViewGfePage.vue"),
    // meta: {
    //   isAuth: true,
    //   isAdmin: false,
    // },
  },
  {
    path: "/director/dashboard",
    name: "Director_Dashboard",
    component: () =>
      import("../components/Customer/CustomerDashboard/GFE/List_GFE.vue"),
    meta: {
      isAuth: true,
      isAdmin: false,
    },
  },
  {
    path: "/customer/director",
    name: "CustomerMedicalDirector",
    component: () =>
      import("../components/Customer/CustomerDashboard/GFE/List_GFE.vue"),
    meta: {
      isAuth: true,
      isAdmin: false,
    },
  },
  {
    path: "/customer/director/contracts",
    name: "CustomersAssociatedDirectorContracts",
    component: () =>
        import("../components/CustomerMedicalDirector/Contracts.vue"),
    meta: {
      isAuth: true,
      isAdmin: false,
    },
  },
  {
    path: "/user/profile",
    name: "UpdateCredentials",
    component: () => import("../views/UpdateCredentials.vue")
  },
  {
    path: "/user/api",
    name: "CustomerApiCredentials",
    component: () => import("../views/CustomerApiCredentials.vue")
  },
  {
    path: "/patient/gfes",
    name: "PatientGFEs",
    component: () =>
      import("@/components/Customer/CustomerDashboard/GFE/List_GFE.vue"),
    meta: {
      isAuth: true,
      isAdmin: false,
    },
  },
  {
    path: "/customer/dashboard",
    name: "CustomerDashboard",
    component: () =>
      import("@/components/Customer/CustomerDashboard/CustomerDashboard.vue"),
    meta: {
      isAuth: true,
      isAdmin: false,
    },
  },
  {
    path: "/invoice/list",
    name: "InvoiceList",
    component: () => import("@/views/InvoiceList.vue"),
    // meta: {
    //   isAuth: true,
    //   isAdmin: true,
    // },
  },
  {
    path: "/invoice",
    name: "ViewInvoicePage",
    component: () => import("@/views/ViewInvoicePage.vue"),
    // meta: {
    //   isAuth: true,
    //   isAdmin: true,
    // },
  },
  {
    path: "/patient/home",
    name: "PatientHomePage",
    component: () => import("@/views/PatientHomePage"),
  },
  {
    path: "/patient/call",
    name: "GfeCallPage",
    component: () => import("@/views/GfeCallPage.vue"),
  },
  {
    path: "/manufacturer/edit",
    name: "EditManufacturer",
    component: () => import("@/components/Admin/EditManufacturer.vue"),
    meta: {
      isAuth: true,
      isAdmin: true,
    },
  },
  {
    path: "/compliance/edit",
    name: "EditCompliance",
    component: () => import("@/components/Admin/EditCompliance.vue"),
    meta: {
      isAuth: true,
      isAdmin: true,
    },
  },
  {
    path: "/treatment/edit",
    name: "EditTreatment",
    component: () =>
      import("@/components/Admin/CategoryAndTreatments/EditTreatment.vue"),
    meta: {
      isAuth: true,
      isAdmin: true,
    },
  },
  {
    path: "/forgot/password",
    name: "ForgotPassword",
    component: () => import("@/views/ForgotPassword.vue"),
  },
  {
    path: "/password/reset",
    name: "ResetPassword",
    component: () => import("@/views/ResetPassword.vue"),
  },
  {
    path: "/questions/list",
    name: "QuestionsList",
    component: () => import("@/components/Admin/Questions/QuestionsList.vue"),
    // meta: {
    //   isAuth: true,
    //   isAdmin: true,
    // },
  },
  {
    path: "/question",
    name: "AddAndUpdateQuestion",
    component: () =>
      import("@/components/Admin/Questions/AddAndUpdateQuestion.vue"),
    meta: {
      isAuth: true,
      isAdmin: true,
    },
  },
  {
    path: "/advertisement/add",
    name: "AddAdvertisement",
    component: () =>
      import(
        "@/components/Customer/CustomerDashboard/Advertisement/AddAdvertisement"
      ),
    meta: {
      isAuth: true,
      isAdmin: false,
    },
  },
  {
    path: "/gfes/queued",
    name: "ViewQueuedGfes",
    component: () => import("@/components/Admin/ViewQueuedGfes.vue"),
    meta: {
      isAuth: true,
      isAdmin: true,
    },
  },
  {
    path: "/gfes/inprogress",
    name: "ViewInProgressGfes",
    component: () => import("@/components/Admin/ViewInProgressGfes.vue"),
    meta: {
      isAuth: true,
      isAdmin: true,
    },
  },
  {
    path: "/operating/hours",
    name: "OperatingHours",
    component: () => import("@/components/Admin/OperatingHours/OperatingHoursList"),
    meta: {
      isAuth: true,
      isAdmin: true,
    },
  },
  {
    path: "/operating/hours/edit",
    name: "EditOperatingHours",
    component: () => import("@/components/Admin/OperatingHours/EditOperatingHours"),
    meta: {
      isAuth: true,
      isAdmin: true,
    },
  },
  {
    path: "/customer/redirectToPayment",
    name: "RedirectCustomerToPayment",
    component: () => import("@/views/RedirectCustomerToPayment")
  },
  {
    path: "/provider/show/:id",
    name: "ShowProvider",
    component: () => import("@/components/Admin/ShowProvider"),
    meta: {
      isAuth: true,
      isAdmin: true,
    },
  },
  {
    path: "/ticker/add",
    name: "AddTicker",
    component: () => import("@/components/Admin/Ticker/AddTicker"),
    meta: {
      isAuth: true,
      isAdmin: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  var checkUser = JSON.parse(localStorage.getItem("user"));
  localStorage.setItem("invoiceId", parseInt(from.query.id));
  var isRemembered = localStorage.getItem("rememberMe");

  if (to.matched.some((record) => record.meta.isAuth)) {
    if (checkUser && checkUser.payload.token !== null) {
      if (to.matched.some((record) => record.meta.isAdmin)) {
        if (checkUser.payload.data.type.toLowerCase() == "admin") {
          next();
        } else {
          if (checkUser.payload.data.type.toLowerCase() == "customer") {
            next({ name: "CustomerDashboard" });
          } else if (checkUser.payload.data.type.toLowerCase() == "provider") {
            next({ name: "Provider_Dashboard" });
          } else if (
            checkUser.payload.data.type.toLowerCase() == "medical director" ||
            checkUser.payload.data.type.toLowerCase() == "clinical director"
          ) {
            next({ name: "Director_Dashboard" });
          } else if (
            checkUser.payload.data.type.toLowerCase() ==
            "customer medical director"
          ) {
            next({ name: "CustomerMedicalDirector" });
          }
        }
      } else {
        // next();
        if (checkUser.payload.data.type.toLowerCase() == "admin") {
          next({ name: "AdminDashboard" });
        } else if (checkUser.payload.data.type.toLowerCase() == "customer") {
          next();
        } else if (
          checkUser.payload.data.type.toLowerCase() == "medical director" ||
          checkUser.payload.data.type.toLowerCase() == "clinical director"
        ) {
          next();
        } else if (checkUser.payload.data.type.toLowerCase() == "provider") {
          next();
        } else if (
          checkUser.payload.data.type.toLowerCase() ==
          "customer medical director"
        ) {
          next();
        }
      }
    } else {
      next({ name: "home" });
    }
  } else {
    if (
      to.path == "/" ||
      to.path == "/customer/signup" ||
      to.path == "/provider/signup" ||
      to.path == "/director/signup" ||
      to.path == "/customer/contracts" ||
      to.path == "/provider/contracts" ||
      to.path == "/forgot/password" ||
      to.path == "/password/reset"
    ) {
      if (checkUser?.payload) {
        if (checkUser.payload.data.type.toLowerCase() == "admin") {
          next({ name: "AdminDashboard" });
        } else if (checkUser.payload.data.type.toLowerCase() == "customer") {
          next({ name: "CustomerDashboard" });
        } else if (
          checkUser.payload.data.type.toLowerCase() == "medical director" ||
          checkUser.payload.data.type.toLowerCase() == "clinical director"
        ) {
          next({ name: "Director_Dashboard" });
        } else if (checkUser.payload.data.type.toLowerCase() == "provider") {
          next({ name: "Provider_Dashboard" });
        } else if (
          checkUser.payload.data.type.toLowerCase() ==
          "customer medical director"
        ) {
          next({ name: "CustomerMedicalDirector" });
        }
      } else {
        next();
      }
    } else {
      next();
    }
  }
});

export default router;
