import { createStore, ref } from "vuex";
import user from "./user.store";
import gfe from "./gfe.store";
import provider from "./provider.store";
import invoice from "./invoice.store";
import admin from "./admin.store";
import patient from "./patient.store";
import customer from "./customer.store";
import question from "./question.store";
import advertisement from "./advertisement.store";
import leftMenuItem from "./leftMenuItem.store";
import operatingHour from "./operatingHour.store";
import pusher from "./pusher.store";
import ticker from "./ticker.store";

export default createStore({
	modules: {
		user,
		gfe,
		provider,
		invoice,
		admin,
		patient,
		customer,
		question,
		advertisement,
		leftMenuItem,
		operatingHour,
		pusher,
		ticker,
	},
});
