import tickerService from "../Services/ticker.service";

const state = {};

const getters = {};

const mutations = {};

const actions = {
  async fetchTicker({ commit }) {
    const response = await tickerService.fetchTicker();
    return response;
  },
  async createTicker({ commit }, ticker ) {
    const response = await tickerService.creatTicker(ticker);
    return response;
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
