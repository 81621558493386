import invoiceService from "../Services/invoice.service";

const state = {};

const getters = {};

const mutations = {};

const actions = {
  async fetchInvoice({ commit }) {
    const response = await invoiceService.fetchInvoice();

    return response;
  },

  async createInvoiceSettings(
    { commit },
    invoiceSettingsData = {
      subscriptionType: "detailed",
      customerId,
      gracePeriod,
      subscriptionFee,
    }
  ) {
    const response = await invoiceService.CreateInvoice(invoiceSettingsData);
    return response;
  },

  async fetchInvoiceById({ commit }, id) {
    const response = await invoiceService.fetchInvoiceById(id);
    return response;
  },

  async markInvoicePayment({ commit }, data) {
    const response = await invoiceService.markInvoicePayment(data);
    return response;
  },

  async makeInvoicePayment({ commit }, invoiceId) {
    const response = await invoiceService.makeInvoicePayment(invoiceId);
    return response;
  },

  async filterInvoiceByStateId({ commit }, stateId) {
    const response = await invoiceService.filterInvoiceByStateId(stateId);
    return response;
  },

  async resendInvoiceToCustomer({ commit }, invoiceId) {
    const response = await invoiceService.resendInvoiceToCustomer(invoiceId);
    return response;
  },

  async generateInvoiceToCustomer({ commit }, customerId) {
    const response = await invoiceService.generateInvoiceToCustomer(customerId);
    return response;
  },

  async fetchPastDueBillings({ commit }, customerId) {
    const response = await invoiceService.fetchPastDueBillings(customerId);
    return response;
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
