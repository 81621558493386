import cookie from "./cookieHelpers";

async function makeApiCall(url, method, body = null) {
  const requestOptions = {
    method: method,
    body: body == null ? null : JSON.stringify(body),
    headers: {
      "content-type": "application/json",
    },
  };

  const token = cookie.getCookie("customerContractToken")
    ? cookie.getCookie("customerContractToken")
    : cookie.getCookie("providerContractToken")
    ? cookie.getCookie("providerContractToken")
    : cookie.getCookie("token");

  if (token) {
    requestOptions.headers.Authorization = `Bearer ${token}`;
  } else {
    requestOptions.headers["api-key"] =
      "Hi, you I am the API key. you can use me to authenticate";
  }

  return new Promise((resolve, reject) => {
    fetch(url, requestOptions)
      .then((resp) => resp?.json())
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

async function makeFileApiCall(url, method, body = null) {
  const requestOptions = {
    method: method,
    body: body == null ? null : body,
  };

  const token = cookie.getCookie("token");

  if (token) {
    requestOptions.headers.Authorization = `Bearer ${token}`;
  }

  return new Promise((resolve, reject) => {
    fetch(url, requestOptions)
      .then((resp) => resp.json())
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export default {
  makeApiCall,
  makeFileApiCall,
};
