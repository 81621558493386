import url from "../constants/urls";
import apiHelper from "../helper/apiHelper";

async function fetchDashboardData(tz) {
  return await apiHelper.makeApiCall(
    url.API_URL + url.ADMIN_DASHBOARD + `?tz=${encodeURIComponent(tz)}`,
    "GET",
    null
  );
}

async function fetchRevenueData() {
  return await apiHelper.makeApiCall(
    url.API_URL + url.GET_REVENUE_CHART_DATA,
    "GET",
    null
  );
}

export default {
  fetchDashboardData,
  fetchRevenueData,
};
