import customerService from "../Services/customer.service";

const state = {};

const getters = {};

const mutations = {};

const actions = {
  async fetchTotalCustomerCount({ commit }) {
    const response = await customerService.fetchTotalCustomerCount();
    return response;
  },

  async fetchTotalCustomerCountCurrentMonth({ commit }, data) {
    const response = await customerService.fetchTotalCustomerCountCurrentMonth(
      data
    );
    return response;
  },

  async fetchCustomerContracts() {
    const response = await customerService.fetchCustomerContracts();
    return response;
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
