import url from "../constants/urls";
import apiHelper from "../helper/apiHelper";

async function notifyCallEnd({ gfeId, endedBy }) {
  return await apiHelper.makeApiCall(
    url.API_URL + url.NOTIFY_END_CALL + `?id=${gfeId}&endedby=${endedBy}`,
    "POST"
  );
}

async function fetchGfeSummary(
  providerId,
  patientId,
  customerId,
  fromCreatedAt,
  toCreatedAt,
  fromCompletedAt,
  toCompletedAt,
  fromStartedAt,
  toStartedAt,
  status
) {
  let apiUrl = url.API_URL + url.GFE_COUNT_AND_SUMMARY + "?";

  if (providerId != null)
    apiUrl += `providerId=${encodeURIComponent(providerId)}&`;
  if (patientId != null)
    apiUrl += `patientId=${encodeURIComponent(patientId)}&`;
  if (customerId != null)
    apiUrl += `customerId=${encodeURIComponent(customerId)}&`;
  if (fromCreatedAt != null)
    apiUrl += `fromCreatedAt=${encodeURIComponent(fromCreatedAt)}&`;
  if (toCreatedAt != null)
    apiUrl += `toCreatedAt=${encodeURIComponent(toCreatedAt)}&`;
  if (fromCompletedAt != null)
    apiUrl += `fromCompletedAt=${encodeURIComponent(fromCompletedAt)}&`;
  if (toCompletedAt != null)
    apiUrl += `toCompletedAt=${encodeURIComponent(toCompletedAt)}&`;
  if (fromStartedAt != null)
    apiUrl += `fromStartedAt=${encodeURIComponent(fromStartedAt)}&`;
  if (toStartedAt != null)
    apiUrl += `toStartedAt=${encodeURIComponent(toStartedAt)}&`;
  if (status != null) apiUrl += `status=${encodeURIComponent(status)}`;

  return await apiHelper.makeApiCall(apiUrl, "GET", null);
}

async function fetchMonthlyGfeTrend(
  customerId = null,
  statuses = [],
  tz = null
) {
  let apiUrl = url.API_URL + url.FETCH_MONTHY_GFE_TREND + "?";

  if (customerId != null) {
    apiUrl += `customerid=${customerId}&`;
  }

  if (statuses && statuses != null && statuses.length > 0) {
    statuses.forEach((val) => {
      apiUrl += `status=${val}&`;
    });
  }

  if (customerId != null) {
    apiUrl += `tz=${encodeURIComponent(tz)}&`;
  }

  return await apiHelper.makeApiCall(apiUrl, "GET", null);
}

async function fetchInProgressGfes() {
  return await apiHelper.makeApiCall(url.API_URL + url.GFES_IN_PROGRESS, "GET");
}

async function fetchGFEsByLocationId({ locationId, customerId }) {
  return await apiHelper.makeApiCall(
    url.API_URL +
      url.FETCH_GFE +
      `?customerlocationid=${locationId}&customerid=${customerId}`,
    "GET"
  );
}

async function updateGfe(data) {
  return await apiHelper.makeApiCall(url.API_URL + url.UPDATE_GFE, "PUT", data);
}

async function startGfeCallRecording(data) {
  return await apiHelper.makeApiCall(
    url.API_URL + url.START_RECORDING,
    "POST",
    data
  );
}

export default {
  notifyCallEnd,
  fetchGfeSummary,
  fetchMonthlyGfeTrend,
  fetchInProgressGfes,
  fetchGFEsByLocationId,
  updateGfe,
  startGfeCallRecording,
};
