import { reactive } from "vuex";
const store = {
	state: { pusher: null },
	getters: {},
	mutations: {},
	actions: {
		getPusher({ commit }) {
			if (store.state.pusher == null) {
				store.state.pusher = new Pusher("709ac808fef9d853b91e", {
					cluster: "ap2",
				});
			}
			return store.state.pusher;
		},
	},
};
export default store;
