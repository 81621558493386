import operatingHoursService from "../Services/operatingHours.service";

const state = {};

const getters = {};

const mutations = {};

const actions = {
  async fetchOperatingHours({ commit }) {
    const response = await operatingHoursService.fetchOperatingHours();
    return response;
  },
  async updateOperatingHours({ commit }, operatingHours ) {
    const response = await operatingHoursService.updateOperatingHours(operatingHours);
    return response;
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
