export default {
  "Sign up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اشتراك"])},
  "Company Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الشركة"])},
  "Contact Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم جهة الاتصال"])},
  "Contact Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الاتصال"])},
  "Company Address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عنوان الشركة"])},
  "Contact Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تواصل بالبريد الاكتروني"])},
  "DBA Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم DBA"])},
  "Business Website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["موقع الأعمال التجارية"])},
  "Do you have multiple locations?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل لديك مواقع متعددة؟"])},
  "True": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حقيقي"])},
  "False": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خطأ شنيع"])},
  "Dropdown Button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["زر القائمة المنسدلة"])},
  "California": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كاليفورنيا"])},
  "Billing Contact Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم جهة اتصال الفوترة"])},
  "Billing Phone Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفواتير رقم الهاتف"])},
  "Medical Group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المجموعة الطبية"])},
  "Medical Directors Info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات المديرين الطبيين "])},
  "First Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم الاول"])},
  "Last Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الكنية"])},
  "Phone Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الهاتف"])},
  "Must be Director's Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب أن يكون البريد الإلكتروني للمدير"])},
  "License Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع الرخصة"])},
  "Medical License Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الترخيص الطبي"])},
  "Register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يسجل"])},
  "sign up image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صورة التسجيل"])},
  "Login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل الدخول"])},
  "User Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المستخدم"])},
  "Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور"])},
  "Customer Sign up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل العميل"])},
  "Provider Sign up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل مزود"])},
  "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مطلوب"])},
  "optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختياري"])}
}