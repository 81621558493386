import url from "../constants/urls";
import apiHelper from "../helper/apiHelper";

async function fetchTicker() {
  return await apiHelper.makeApiCall(
    url.API_URL + url.FETCH_TICKER,
    "GET",
    null
  );
}
async function creatTicker(data) {
    return await apiHelper.makeApiCall(
        url.API_URL + url.CREATE_TICKER,
        "POST",
        data
    );
}
export default {
    fetchTicker,
    creatTicker
};
