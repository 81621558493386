import url from "../constants/urls";
import apiHelper from "../helper/apiHelper";

async function fetchTotalCustomerCount() {
  return await apiHelper.makeApiCall(
    url.API_URL + url.FETCH_TOTAL_CUSTOMER_COUNT + "?status=approved",
    "GET",
    null
  );
}

async function fetchTotalCustomerCountCurrentMonth(data) {
  return await apiHelper.makeApiCall(
    url.API_URL +
      url.FETCH_TOTAL_CUSTOMER_COUNT +
      `?status=approved&fromCreatedAt=${data?.currentMonthStartDate}&toCreatedAt=${data?.currentMonthLastDate}`,
    "GET",
    null
  );
}

async function fetchCustomerContracts() {
  return await apiHelper.makeApiCall(
      url.API_URL +
      url.CUSTOMER_CONTRACTS,
      "GET",
      null
  );
}

export default {
  fetchTotalCustomerCount,
  fetchTotalCustomerCountCurrentMonth,
  fetchCustomerContracts
};
